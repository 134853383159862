<template>
  <div class="container">
    <router-view style="height: calc(100vh - 50px)"></router-view>
    <van-tabbar class="no_touch" v-model="active" active-color="#fff" inactive-color="#fff">
      <van-tabbar-item replace to="/">
        <span>设备</span>
        <template #icon="props">
          <img :src="props.active ? iconDevice.active : iconDevice.inactive"/>
        </template>
      </van-tabbar-item>
      <van-tabbar-item icon="search" :badge="msgBadge" replace to="/message">
        <span>消息</span>
        <template #icon="props">
          <img :src="props.active ? iconMsg.active : iconMsg.inactive"/>
        </template>
      </van-tabbar-item>
      <van-tabbar-item icon="setting-o" replace to="/function">
        <span>功能</span>
        <template #icon="props">
          <img :src="props.active ? iconFun.active : iconFun.inactive"/>
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
// @ is an alias to /src
import imgDevice from '@/assets/main_tab_user.png'
import imgDevicePress from '@/assets/main_tab_user_press.png'
import imgMsg from '@/assets/main_tab_alert.png'
import imgMsgPress from '@/assets/main_tab_alert_press.png'
import imgFun from '@/assets/main_tab_function.png'
import imgFunPress from '@/assets/main_tab_function_press.png'

export default {
  name: 'Home',
  data() {
    return {
      active: 0,
      msgBadge: null,
      iconDevice: {
        active: imgDevicePress,
        inactive: imgDevice,
      },
      iconMsg: {
        active: imgMsgPress,
        inactive: imgMsg,
      },
      iconFun: {
        active: imgFunPress,
        inactive: imgFun,
      },
    }
  },
  mounted() {
  },
  methods: {}
}
</script>

<style scoped>
.container {
  background-color: #f6f8f9;
  height: 100vh;
  overflow: hidden;
}

.van-tabbar-item {
  background-color: #11B7F3;
}

</style>