export function date2Str(date) {
    let month = date.getMonth() + 1
    if (String(month).length === 1) {
        month = '0' + String(month)
    }

    let day = date.getDate()
    if (String(day).length === 1) {
        day = '0' + String(day)
    }


    let hours = date.getHours()
    if (String(hours).length === 1) {
        hours = '0' + String(hours)
    }

    let minutes = date.getMinutes()
    if (String(minutes).length === 1) {
        minutes = '0' + String(minutes)
    }

    let seconds = date.getSeconds()
    if (String(seconds).length === 1) {
        seconds = '0' + String(seconds)
    }
    return date.getFullYear() + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds
}

export function date2Str2(date) {
    let month = date.getMonth() + 1
    if (String(month).length === 1) {
        month = '0' + String(month)
    }

    let day = date.getDate()
    if (String(day).length === 1) {
        day = '0' + String(day)
    }


    let hours = date.getHours()
    if (String(hours).length === 1) {
        hours = '0' + String(hours)
    }

    let minutes = date.getMinutes()
    if (String(minutes).length === 1) {
        minutes = '0' + String(minutes)
    }

    return date.getFullYear() + '/' + month + '/' + day + ' ' + hours + ':' + minutes
}

export function utc2local(date) {
    return new Date(date.getTime() - date.getTimezoneOffset()*60*1000)
}

export function utc2localStr(date) {
    return date2Str(utc2local(date))
}

export function utc2localStr2(date) {
    return date2Str2(utc2local(date))
}

export function str2Date(str) {
    console.log(str)
    const a = str.split(' ')
    const b = a[0].split('-')
    const y = b[0]
    const mon = b[1]
    const d = b[2]
    const c = a[1].split(':')
    const h = c[0]
    const min = c[1]
    const s = c[2]
    return new Date(y, mon - 1, d, h, min, s)
}

export function str2Date2(str) {
    console.log(str)
    const a = str.split(' ')
    const b = a[0].split('/')
    const y = b[0]
    const mon = b[1]
    const d = b[2]
    const c = a[1].split(':')
    const h = c[0]
    const min = c[1]
    return new Date(y, mon - 1, d, h, min)
}

// new Date()得到的时间，月份是从0开始的
export function dateAddMonth(date) {
    date.setMonth(date.getMonth() + 1)
    return date
}