import Vue from 'vue'
import App from './App.vue'
import router from './router'
import {Icon, Switch, Toast, Slider, Button, Dialog, RadioGroup, Radio, Divider, DatetimePicker, Popup, Tabbar, TabbarItem, Cell, CellGroup, List, PullRefresh} from "vant";
// 去除浏览器style
import 'normalize.css/normalize.css'

Vue.use(Icon)
Vue.use(Switch)
Vue.use(Toast)
Vue.use(Slider)
Vue.use(Button)
Vue.use(Dialog)
Vue.use(RadioGroup)
Vue.use(Radio)
Vue.use(Divider)
Vue.use(DatetimePicker)
Vue.use(Popup)
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(List);
Vue.use(PullRefresh);

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
