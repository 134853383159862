export const Family_cellphone = "0002";//亲情号码
export const Location_now = "0039";//即时定位
export const Temperature_interval = "1090"// 体温测量间隔
export const Temperature_Now = "1091";//体温检测
export const Contacts = "1304";//通讯录/白名单
// export const Contacts = "1304";//智能提醒
export const Heart_rate_interval = "2815";//心率上传间隔
export const GPS_interval = "2819";//GPS上传间隔
export const Contacts_Switch = "2822";//白名单开关
export const Red_Message_Switch = "2824";//红键短信开关
export const Heart_rate_range = "3010";//心率报警范围
export const Health_check = "3011";//健康检测
