<template>
<div class="contr-card no_touch">
  <img class="img-icon" :src="icon">
  <span class="text-value">{{value}}</span>
</div>
</template>

<script>
export default {
  name: "HomeCard",
  props: {
    icon: String,
    value: String,
  }
}
</script>

<style scoped>
.contr-card {
  border-radius: 8px;
  background-color: white;
  /* width: 90%; */
  height: 40px;
  margin: 10px;
  padding: 10px;
  text-align: start;

  display: flex;
  flex-direction: row;
  align-items: center;
}
.img-icon {
  width: 30px;
  margin-right: 6px;
}
.text-value {
  white-space: pre-wrap;
}
</style>