<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
  export default {
    created() {
      this.$nextTick(() => {
        // 禁用右键
        document.oncontextmenu = new Function("event.returnValue=false");
        // 禁用选择
        document.onselectstart = new Function("event.returnValue=false");
      });
    }
  };
</script>
<style>
#app {
  height: 100vh;
  width: 100vw;
}
/* 禁止手机上长按复制文本、图片 */
.no_touch {
    -webkit-touch-callout:none;  /*系统默认菜单被禁用*/
    -webkit-user-select:none; /*webkit浏览器*/
    -khtml-user-select:none; /*早期浏览器*/
    -moz-user-select:none; /*火狐*/
    -ms-user-select:none;  /*IE10*/
    user-select:none;
  }
</style>
