<template>
  <!--  <div>-->
  <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
    <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
    >
      <div v-for="item in messageList" :key="item.ExceptionID">
        <!--   转时间     -->
        <div class="contr-item">
          <span class="text-item">{{ formatTime(item.CreateDate) }}</span>
        </div>
        <div class="contr-content">
          <img class="img-avatar" :src=getAvatar(item.Avatar) alt=""/>
          <div class="contr-content-text">
            <div style="font-size: 14px">{{ item.Nickname }}</div>
            <div style="margin-top: 4px">{{ item.Message }}</div>
          </div>
        </div>
      </div>
    </van-list>
  </van-pull-refresh>
  <!--  </div>-->
</template>

<script>

import {post} from "@/router/httpUtils";
import {Language, State_0, State_100} from "@/constants/contants";
import {Toast} from "vant";
import {str2Date2, utc2localStr2} from "@/util/timeUtil";
import headImage from "@/assets/app_defult_headimage.png";


export default {
  name: "Message",
  data() {
    return {
      loading: false,
      finished: false,
      refreshing: false,
      pageNo: 1,
      messageList: [{
        "Avatar": "http://api.xiaoxinhuan.com/UserAvatar/avatar_d_2664821.jpg?t=20210324204915309",
        "Message": "SOS！Qin需要帮助，请注意！",
        "Nickname": "Qin",
        "DeviceName": "H002-51892",
        "ModelName": "H002",
        "ExceptionID": 319979647,
        "SerialNumber": "866058040151892",
        "DeviceID": 2664821,
        "GeoFenceID": 0,
        "NotificationType": 5,
        "CreateDate": "2021/03/31 17:01",
        "Deleted": 0,
        "GeoName": "",
        "Lat": 31.300158033589700,
        "Lng": 121.466385926928000,
        "Address": "上海市上海市虹口区奎照路/离水仙家园约19米/离奎江公寓约21米",
        "DeviceDate": "2021/03/31 17:01",
        "OLat": 0.0,
        "OLng": 0.0,
        "FenceNo": 0,
        "ExceptionName": "紧急报警"
      }]
    }
  },
  mounted() {
    // this.getExceptionMessage()
    this.refreshing = true
    this.onRefresh()
  },
  methods: {
    // 报警信息
    getExceptionMessage() {
      post('/api/ExceptionMessage/ExcdeptionListWhitoutCode', {
        "DataCode": "",
        "Id": localStorage.UserId,
        // eslint-disable-next-line no-undef
        "Language": Language,
        "PageCount": 20,
        "PageNo": this.pageNo,
        "Token": localStorage.Token,
        "TypeID": 0,
        "UserID": localStorage.UserId
      }).then(res => {
        console.log(res)

        if (res.State != State_0 && res.State != State_100) {
          Toast.fail({
            message: '获取报警消息失败'
          })
          return
        }

        this.messageList = this.messageList.concat(res.Items)
        console.log(this.messageList)

        if (res.Items.length < 20) {
          this.finished = true
        }
      }).catch(err => {
        console.log(err)
      }).then(() => {
        this.loading = false
      })
    },
    onLoad() {
      if (this.refreshing) {
        this.messageList = [];
        this.refreshing = false;
      }
      this.getExceptionMessage()
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    },
    getAvatar(url) {
      return url ? url : headImage
    },
    formatTime(time) {
      var date = str2Date2(time)
      return utc2localStr2(date)
    }
  }
}
</script>

<style scoped>
.contr-item {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.text-item {
  text-align: center;
  border-radius: 10px;
  background-color: #969799;
  padding: 2px 8px;
  font-size: 12px;
  color: white;
}

.img-avatar {
  border-radius: 50px;
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.contr-content {
  display: flex;
  flex-direction: row;

  background-color: white;
  border-radius: 6px;
  margin: 10px 15px;
  padding: 10px ;
  border: 1px solid darkgray;
}

.contr-content-text {

}
.van-pull-refresh {
  overflow: auto;
}
</style>