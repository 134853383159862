import { render, staticRenderFns } from "./Function.vue?vue&type=template&id=128465c6&scoped=true&"
import script from "./Function.vue?vue&type=script&lang=js&"
export * from "./Function.vue?vue&type=script&lang=js&"
import style0 from "./Function.vue?vue&type=style&index=0&id=128465c6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "128465c6",
  null
  
)

export default component.exports