export const APP_ID = 75
export const Language = "zh-CN"
export const MapType = "baidu"
export const TimeOffset = 8.0

export const AppToken = 'bd481f06d2e19accab48965ada3b8f14d66d02b78b345d67908e4dc8f9d8860aefe0ad391d8963586632c4cba89bf64a'

export const State_0 = 0;//成功
export const State_5 = 5;//照片正在传输中
export const State_6 = 6;//存储空间已满
export const State_7 = 7;//设置已保存，等待设备上线
export const State_10 = 10;//该时间段无历史数据
export const State_100 = 100;//无数据返回
export const State_200 = 200;//无数据返回
export const State_1000 = 1000;//账户不存在
export const State_1001 = 1001;//用户名已存在
export const State_1002 = 1002;//用户名，登录名，密码或设备IMEI不能为空
export const State_1003 = 1003;//注册失败
export const State_1004 = 1004;//密码不正确
export const State_1005 = 1005;//Email地址不匹配
export const State_1100 = 1100;//IMEI 不存在
export const State_1101 = 1101;//设备不存在
export const State_1102 = 1102;//设备已被注册
export const State_1103 = 1103;//添加设备失败
export const State_1105 = 1105;//您已经是该设备的主控账号,无需再次添加!
export const State_1106 = 1106;//您已获得该设备的访问权限！
export const State_1107 = 1107;//可以申请监控该设备
export const State_1500 = 1500;//已请求查看该设备,等待设备主控号同意！
export const State_1501 = 1501;//您已提交了相同的请求,请不要重复请求！
export const State_1300 = 1300;//设备未定位
export const State_1700 = 1700;//该第三方用户已经绑定过其他用户
export const State_1702 = 1702;//未绑定
export const State_1800 = 1800;//设备不在线
export const State_1801 = 1801;//发送超时
export const State_1802 = 1802;//下发失败
export const State_1803 = 1803;//指令已下发
export const State_2001 = 2001;//无数据
export const State_2002 = 2002;//参数不正确
export const State_2200 = 2200;//未设中控号
export const State_3700 = 3700;//短信验证码不正确
export const State_3701 = 3701;//短信验证码已过期
export const State_3800 = 3800;//该邮箱已绑定其他账号
export const State_3900 = 3900;//IMEI验证通过,需要输入验证码

export const State_4000 = 4000;//验证码错误

export const app_State_5 = '照片正在传输中'
export const app_State_10 = '该时间段无历史数据'
export const app_State_100 = '无数据'
export const app_State_1000 = '用户不存在'
export const app_State_1001 = '用户名已存在'
export const app_State_1002 = '用户名，登录名，密码或设备IMEI不能为空'
export const app_State_1003 = '注册失败'
export const app_State_1004 = '密码错误'
export const app_State_1005 = '邮箱与用户名不匹配'
export const app_State_1100 = '设备IMEI号不存在'
export const app_State_1101 = '设备不存在'
export const app_State_1102 = '设备已被注册'
export const app_State_1103 = '添加设备失败'
export const app_State_1104 = '设备密码错'
export const app_State_1105 = '您已经是该设备的主控账号,无需再次添加!'
export const app_State_1106 = '您已获得该设备的访问权限！'
export const app_State_1500 = '已请求查看该设备,等待设备主控号同意！'
export const app_State_1501 = '您已提交了相同的请求,无需再次请求！'
export const app_State_1300 = '设备未定位'
export const app_State_1700 = '该第三方用户已经绑定过其他用户'
export const app_State_1800 = '设备离线,下发失败'
export const app_State_1801 = '发送超时'
export const app_State_1802 = '下发失败'
export const app_State_2001 = '无数据'
export const app_State_2002 = '参数不正确'
export const app_State_2800 = '该时段无行程数据'
export const app_State_3000 = '无OBD状态记录'
export const app_State_3800 = '该邮箱已绑定其他账号'
export const app_State_4000 = '验证码错误'
export const app_State_5000 = '服务器错误'
export const app_State_3700 = '短信验证码不正确'
export const app_State_3701 = '短信验证码已过期'

export const OrderSet_Send = '指令已下发!'
export const OrderSet_SendSuccess = '指令下发成功!'
export const OrderSet_SendFailure = '指令下发成功设备无响应!'
export const OrderSet_SaveSuccess = '指令保存成功...'

export const app_NetworkError = '网络连接错误'