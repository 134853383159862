import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Device from "@/views/Device";
import Message from "@/views/Message";
import Function from "@/views/Function";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        children: [
            {
                path: '/',
                component: Device,
            }, {
                path: 'message',
                component: Message,
            }, {
                path: 'function',
                component: Function,
            },
        ]
    },
    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    },
    {
        path: '/bindIMEI',
        name: 'BindIMEI',
        component: () => import('../views/BindIMEI.vue')
    },
    {
        path: '/unbindIMEI',
        name: 'UnBindIMEI',
        component: () => import('../views/UnBindIMEI.vue')
    },
    {
        path: '/location',
        name: 'Location',
        component: () => import('../views/Location.vue')
    },
    {
        path: '/step',
        name: 'Step',
        component: () => import('../views/Step.vue')
    },
    {
        path: '/bloodPress',
        name: 'BloodPress',
        component: () => import('../views/BloodPress.vue')
    },
    {
        path: '/bloodOxygen',
        name: 'BloodOxygen',
        component: () => import('../views/BloodOxygen.vue')
    },
    {
        path: '/temperature',
        name: 'Temperature',
        component: () => import('../views/Temperature.vue')
    },
    {
        path: '/heartRate',
        name: 'HeartRate',
        component: () => import('../views/HeartRate.vue')
    },
    {
        path: '/sleep',
        name: 'Sleep',
        component: () => import('../views/Sleep.vue')
    },
    {
        path: '/message',
        name: 'Message',
        component: () => import('../views/Message.vue')
    },
    {
        path: '/safeArea',
        name: 'SafeArea',
        component: () => import('../views/SafeArea.vue')
    },
    {
        path: '/safeAreaBrowser',
        name: 'SafeAreaBrowser',
        component: () => import('../views/SafeAreaBrowser.vue')
    },
    {
        path: '/safeAreaList',
        name: 'SafeAreaList',
        component: () => import('../views/SafeAreaList.vue')
    },
    {
        path: '/spoor',
        name: 'Spoor',
        component: () => import('../views/Spoor.vue')
    },
    {
        path: '/deviceContacts',
        name: 'DeviceContacts',
        component: () => import('../views/DeviceContacts.vue')
    },
    {
        path: '/notifyList',
        name: 'NotifyList',
        component: () => import('../views/NotifyList.vue'),
    },
    {
        path: '/remindSetting',
        name: 'RemindSetting',
        component: () => import('../views/ReminderSetting.vue'),
    },
    {
        path: '/editUserInfo',
        name: 'EditUserInfo',
        component: () => import('../views/EditUserInfo.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
