<template>
  <!-- 手环设置 -->
  <div class="container no_touch">
    <van-cell-group title="通讯">
      <!--   绑定亲情号   -->
      <van-cell title="亲情号码" @click="setFamilyCellphone"/>
      <!--   通讯录/白名单   -->
      <van-cell title="通讯录/白名单" is-link @click="goContracts"/>
      <van-cell title="白名单开关">
        <template #right-icon>
          <van-switch class="item-switch" v-model="swContracts" @change="onSwitchChange('whiteContracts')"/>
        </template>
      </van-cell>
      <van-cell title="红键短信开关">
        <template #right-icon>
          <van-switch class="item-switch" v-model="swRedMessage" @change="onSwitchChange('redMessage')"/>
        </template>
      </van-cell>
    </van-cell-group>
    <van-cell-group title="提醒">
      <!--   智能提醒   -->
      <van-cell title="智能提醒设置" is-link to="/notifyList"/>
    </van-cell-group>
    <van-cell-group title="健康">
      <!--   健康监测   -->
      <van-cell title="健康检测" @click="showConfirmDialog('healthCheck')"/>
      <!--   心率报警值设置   -->
      <van-cell title="心率报警范围" @click="setRange"/>
      <!--   心率上传间隔设置   -->
      <van-cell title="心率上传间隔" @click="setInterval('heartRate')"/>
      <!--   体温检测   -->
      <van-cell title="体温检测" @click="showConfirmDialog('temperatureCheck')"/>
      <!--   体温上传间隔设置   -->
      <van-cell title="体温上传间隔" @click="setInterval('temperature')"/>
    </van-cell-group>
    <van-cell-group title="定位">
      <!--   即时定位   -->
      <van-cell title="即时定位" @click="showConfirmDialog('location')"/>
      <!--   GPS上传间隔   -->
      <van-cell title="GPS上传间隔" @click="setInterval('gps')"/>
    </van-cell-group>

    <van-dialog v-model="showCellphonePopup" title="亲情号码" show-cancel-button
                @confirm="sendFamilyCellphoneCommand">
      <van-cell-group>
        <van-field v-model="familyCellphone1" label="红键" placeholder="亲情号码1" type="tel" clearable/>
        <van-field v-model="familyCellphone2" label="黄键" placeholder="亲情号码2" type="tel" clearable/>
        <van-field v-model="familyCellphone3" label="绿键" placeholder="亲情号码3" type="tel" clearable/>
      </van-cell-group>
    </van-dialog>
    <van-dialog v-model="showIntervalDialog" :title="intervalTitle" show-cancel-button
                @confirm="sendIntervalCommand">
      <van-cell-group>
        <van-field v-model="valueInterval" label="分钟" placeholder="间隔(单位：分)" type="digit" clearable/>
      </van-cell-group>
    </van-dialog>
    <van-dialog v-model="showRangeDialog" title="心率报警范围" show-cancel-button
                @confirm="sendHeartRateRangeCommand">
      <van-cell-group>
        <van-field v-model="maxRange" label="最大值" :placeholder="maxPlaceholder" type="digit" clearable/>
        <van-field v-model="minRange" label="最小值" :placeholder="minPlaceholder" type="digit" clearable/>
      </van-cell-group>
    </van-dialog>
  </div>
</template>

<script>
import {Dialog, Toast} from "vant";
import {post, postOpen} from "@/router/httpUtils";
import {
  app_NetworkError,
  app_State_1800, app_State_1801, app_State_1802,
  Language,
  OrderSet_SendSuccess,
  State_0,
  State_100,
  State_1800,
  State_1801, State_1802, State_2200
} from "@/constants/contants";
import {
  Contacts,
  Family_cellphone,
  GPS_interval,
  Heart_rate_interval,
  Heart_rate_range,
  Location_now,
  Health_check,
  Contacts_Switch,
  Temperature_interval,
  Red_Message_Switch,
  Temperature_Now,
} from "@/constants/CmdCode";
import Vue from 'vue'
import {Field} from "vant";

Vue.use(Field)

export default {
  name: "Function",
  data() {
    return {
      showCellphonePopup: false,
      showIntervalDialog: false,
      showRangeDialog: false,
      intervalTitle: '',

      commandList: [{
        "Code": '',
        "Name": '',
        "CmdValue": '',
        "SMSType": Number,
        "SMSContent": ''
      }],

      valueInterval: '',

      maxRange: '',
      minRange: '',
      maxPlaceholder: '',
      minPlaceholder: '',

      familyCellphone1: '',
      familyCellphone2: '',
      familyCellphone3: '',

      gpsInterval: '',
      heartRateInterval: '',
      temperatureInterval: '',

      contracts: '',
      swContracts: false,
      swRedMessage: false,
    }
  },
  mounted() {
    this.getCommandList()
  },
  methods: {
    setFamilyCellphone() {
      this.showCellphonePopup = true
    },
    showConfirmDialog(type) {
      let title
      let sendFunc
      if (type === "healthCheck") {
        title = '健康检测'
        sendFunc = () => {
          this.sendCommand(this.getCommandParams(Health_check))
        }
      } else if (type === "location") {
        title = '即时定位'
        sendFunc = () => {
          this.sendCommand(this.getCommandParams(Location_now))
        }
      } else if (type === "temperatureCheck") {
        title = '体温检测'
        sendFunc = () => {
          this.sendOpenCommand(this.getOpenCommandParams(Temperature_Now, 0))
        }
      } else {
        return
      }

      Dialog.confirm({
        title: title,
        message: '确定要下发' + title + '指令？'
      }).then(sendFunc)
    },
    setInterval(type) {
      if (type === "heartRate") {
        this.showIntervalDialog = true
        this.intervalTitle = '心率上传间隔'
        this.valueInterval = this.heartRateInterval
      } else if (type === "gps") {
        this.showIntervalDialog = true
        this.intervalTitle = 'GPS上传间隔'
        this.valueInterval = this.gpsInterval
      } else if (type === "temperature") {
        this.showIntervalDialog = true
        this.intervalTitle = '体温上传间隔'
        this.valueInterval = this.temperatureInterval
      }
    },
    setRange() {
      this.showRangeDialog = true
      this.maxPlaceholder = '心率最大值'
      this.minPlaceholder = '心率最小值'
    },
    sendIntervalCommand() {
      if (!this.valueInterval) return;

      let code
      if (this.intervalTitle === '心率上传间隔') {
        code = Heart_rate_interval
        this.heartRateInterval = this.valueInterval
      } else if (this.intervalTitle === 'GPS上传间隔') {
        code = GPS_interval
        this.gpsInterval = this.valueInterval
      } else if (this.intervalTitle === '体温上传间隔') {
        code = Temperature_interval
        this.temperatureInterval = this.valueInterval
        this.sendOpenCommand(this.getOpenCommandParams(code, this.valueInterval))
        return
      } else {
        return
      }

      this.sendCommand(this.getCommandParams(code, this.valueInterval))
    },
    sendFamilyCellphoneCommand() {
      let array = [
        this.familyCellphone1,
        this.familyCellphone2,
        this.familyCellphone3,
      ]
      this.sendCommand(this.getCommandParams(Family_cellphone, array.toString()))
    },
    sendHeartRateRangeCommand() {
      const p = this.minRange + '-' + this.maxRange
      this.sendCommand(this.getCommandParams(Heart_rate_range, p))
    },
    getCommandParams(code, params) {
      return {
        "CmdCode": code,
        "DeviceId": localStorage.DeviceId,
        "DeviceModel": localStorage.TypeValue,
        "Params": params ? params : "",
        "Token": localStorage.Token,
        "UserId": -1,
      }
    },
    getOpenCommandParams(code, params) {
      return {
        "CmdCode": code,
        "Params": params ? params : "",
        "Imei": localStorage.imei,
      }
    },
    sendCommand(params) {
      Toast.loading({
        message: '指令发送中...'
      })
      post('/api/Command/SendCommand', params)
          .then(res => {
            console.log(res)
            const state = res.State
            if (res.Content != "NetworkError") {
              if (state == State_0) {
                Toast.success({
                  message: OrderSet_SendSuccess,
                  duration: 1000
                })
                this.getCommandList()
              } else if (state == State_1800) {
                Toast.fail({
                  message: app_State_1800,
                  duration: 3000
                })
              } else if (state == State_1801) {
                Toast.fail({
                  message: app_State_1801,
                  duration: 3000
                })
              } else if (state == State_1802) {
                Toast.fail({
                  message: app_State_1802,
                  duration: 3000
                })
              } else if (state == State_2200) {
                Toast.fail({
                  message: '请先到孝心环设置界面设置主控号码',
                  duration: 3000
                })
              } else {
                Toast.fail({
                  message: OrderSet_SendSuccess,
                  // message: OrderSet_SendFailure,
                  duration: 3000
                })
              }
            } else {
              Toast.fail({
                // eslint-disable-next-line no-undef
                message: app_NetworkError,
                duration: 3000
              })
            }
          })
          // .catch(err => {
            // Toast.fail({
            //   message: err
            // })
          // })
    },
    sendOpenCommand(params) {
      Toast.loading({
        message: '指令发送中...'
      })
      postOpen('http://openapi.xiaoxinhuan.com/Open/SendCommand', params)
          .then(res => {
            console.log(res)
            const state = res.state
            if (res.Content != "NetworkError") {
              if (state == State_0) {
                Toast.success({
                  message: OrderSet_SendSuccess,
                  duration: 1000
                })
                this.getCommandList()
              } else if (state == State_1800) {
                Toast.fail({
                  message: app_State_1800,
                  duration: 3000
                })
              } else if (state == State_1801) {
                Toast.fail({
                  message: app_State_1801,
                  duration: 3000
                })
              } else if (state == State_1802) {
                Toast.fail({
                  message: app_State_1802,
                  duration: 3000
                })
              } else if (state == State_2200) {
                Toast.fail({
                  message: '请先到孝心环设置界面设置主控号码',
                  duration: 3000
                })
              } else {
                Toast.fail({
                  message: OrderSet_SendSuccess,
                  // message: OrderSet_SendFailure,
                  duration: 3000
                })
              }
            } else {
              Toast.fail({
                // eslint-disable-next-line no-undef
                message: app_NetworkError,
                duration: 3000
              })
            }
          })
          // .catch(err => {
            // Toast.fail({
            //   message: err
            // })
          // })
    },
    getCommandList() {
      // 根据设备id返回命令列表，由于暂时只需要支持一部分命令，而且只有一种设备（H003C），所以暂不根据列表渲染ui（这样好看点）
      post('/api/Command/CommandList', {
        "DeviceId": localStorage.DeviceId,
        "Language": Language,
        "Token": localStorage.Token
      }).then(res => {
        console.log(res)
        if (res.State != State_0 && res.State != State_100) {
          Toast.fail({
            message: '获取命令列表失败！'
          })
          return
        }

        this.commandList = res.Items

        res.Items.forEach(item => {
          let paramArray
          switch (item.Code) {
            case Family_cellphone:
              paramArray = item.CmdValue.split(',');
              this.familyCellphone1 = paramArray[0] ? paramArray[0] : ''
              this.familyCellphone2 = paramArray[1] ? paramArray[1] : ''
              this.familyCellphone3 = paramArray[2] ? paramArray[2] : ''
              break;
            case Heart_rate_range:
              paramArray = item.CmdValue.split('-')
              this.minRange = paramArray[0]
              this.maxRange = paramArray[1]
              break
            case GPS_interval:
              this.gpsInterval = item.CmdValue
              break;
            case Heart_rate_interval:
              this.heartRateInterval = item.CmdValue
              break;
            case Contacts:
              this.contracts = item.CmdValue
              break
            case Contacts_Switch:
              this.swContracts = item.CmdValue === "1"
              break
            case Red_Message_Switch:
              this.swRedMessage = item.CmdValue === "1"
              break
            case Temperature_interval:
              this.temperatureInterval = item.CmdValue
          }
        })
      })
      .catch(err => {
        console.log(err)
        Toast.fail({
          message: '获取命令列表失败！'
        })
      })
    },
    goContracts() {
      this.$router.push({
        name: 'DeviceContacts',
        params: {
          CmdValue: this.contracts
        }
      })
    },
    onSwitchChange(type) {
      console.log(type)
      switch(type) {
        case 'whiteContracts': 
          this.sendCommand(this.getCommandParams(Contacts_Switch, this.swContracts ? "1" : "0"))
        break
        case 'redMessage':
          this.sendCommand(this.getCommandParams(Red_Message_Switch, this.swRedMessage ? "1" : "0"))
        break
      }
    }
  }
}
</script>

<style scoped>
.container {
  overflow: auto;
}
</style>